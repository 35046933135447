import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useProductStore = defineStore('product', {

    state: () => ({
        products_productor: {},
        products_coproductor: {},
        products_referral: {},
        filters_products:{
            'search': null,
            'paginate': null,
            'type': '',
            'status': ' ',
            'tags': null,
        },
        paginate: {},
        productslist: {},
        tagsList: {},
        product: {
            meta: {},
            shipping_methods: {},
            status: null,
            store: {},
            recurrence_period: {interval:null, count:null}
        },
        all_status: {},
        all_types: {},
        all_categories: {},
        all_installments: {},
        all_products: {},
        files: {},
        file: {},
        bumps: {},
        bump: {},
        kits:{},
        kit:{},
        upsells: {},
        upsells_templates: {},
        upsells_template: {
            title: null,
            customization: {},
        },
        upsell: {
            customization: {}
        },
        lotes: {},
        lote: {},
        personalized_fields: {},
        personalized_field: {},
        course_content: {},
        course_comments: {},
        course_comment: {},
        lesson: {
            content: {
                questions: [{
                    answers: [],
                }],
                text: null
            },
        },
        module: {},
        module_sequence:{
            lessons:{},
            modules:{},
        },
        lesson_sequence:{},
        total_coproductor: {},
        total_productor: {},
        total_referral: {},
        render_shipping_methods: {},
        coproductors: {},
        coproductor: {},
        integrations:{},
        events_leadlovers: {},
        event_leadlovers: {},
        event_leadlovers_machines: {},
        event_leadlovers_levels: {},
        event_leadlovers_emails: {},
        events_mautic: {},
        event_mautic: {},
        events_mex: {},
        event_mex: {},
    }),

    actions: {

        async tryProducts(params = null) {
            try {
                if(params.type !== 'productor' && params.type !== 'coproductor' && params.type !== 'referral' && params.type !== null){
                    params.type = null
                }
                const response = await Api.get('products/', {params, paramsSerializer: params => {
                    return qs.stringify(params, { type: "productor" , arrayFormat: "brackets"});
                }});
                if(params.type === 'productor'){
                    this.products_productor = response.data.data;
                } else if(params.type === 'coproductor'){
                    this.products_coproductor = response.data.data;
                } else if(params.type === 'referral'){
                    this.products_referral = response.data.data;
                }
                this.paginate = response.data.meta;
                this.tagsList = response.data.tags;
                this.total_coproductor = response.data.total_coproductor;
                this.total_productor = response.data.total_productor;
                this.total_referral = response.data.total_referral;
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryProductsList(id){
            try{
                const response = await Api.get('products/list?id_store=' + id);
                this.productslist = response.data.data
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false
            }
        },

        async tryProductsCategories(){
            try{
                const response = await Api.get('products/categories');
                this.all_categories = response.data.data
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false
            }
        },

        async tryProduct(id) {
            try {
                const response = await Api.get('products/' + id);
                this.product = response.data.data.product;
                this.all_status = response.data.data.all_status;
                this.all_types = response.data.data.all_types;
                this.all_categories = response.data.data.all_categories;
                this.all_installments = response.data.data.all_installments;
                this.all_products = response.data.data.all_products;
                this.files = response.data.data.files;
                this.upsells = response.data.data.upsells;
                this.lotes = response.data.data.lotes;
                this.personalized_fields = response.data.data.product.personalized_fields;
                this.render_shipping_methods = response.data.data.shipping_methods;
                this.bumps = response.data.data.bumps;
                this.kits = response.data.data.kits;
                this.product.shipping_methods = response.data.data.product_shipping_methods;
                this.coproductors = response.data.data.coproductors;
                this.integrations = response.data.data.integrations;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryInstallments(id, params){
            try{
                const response = await Api.post('products/'+ id +'/productor-installments', params)
                this.installments = response.data.data
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //CURSOS
        //CURSOS

        async tryCourseContent(product_id){
            try{
                const response = await Api.get('products/' + product_id + '/course')
                this.course_content = response.data.data;

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryModule(product_id, slug){
            try{
                const response = await Api.get('products/' + product_id + '/course/modules/' + slug)
                this.module = response.data.data
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateModule(product_id, params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.module);

                await Api.post('products/' + product_id + '/course/modules/', formData)
                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateModule(product_id, id, params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.module);

                formData['_method'] = 'put';
                await Api.post('products/' + product_id + '/course/modules/' + id, formData)

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteModule(product_id, id){
            try{
                await Api.delete('products/' + product_id + '/course/modules/' + id);

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateModuleSequence(product_id){
            try{    
                let params = ['lessons', 'modules'];

                let formData = useGlobalStore().getOnlyParams(params, this.module_sequence);

                formData['_method'] = 'put';
                await Api.post('/products/' + product_id + '/course/module-sequence', formData);
                return true;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryLesson(product_id, id){
            try{
                const response = await Api.get('products/' + product_id + '/course/lessons/' + id)
                this.lesson = response.data.data;
                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateLesson(id, params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.lesson);

                await Api.post('products/' + id + '/course/lessons', formData)
                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateLesson(product_id, params, id, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.lesson);

                formData['_method'] = 'put';
                await Api.post('products/' + product_id + '/course/lessons/' + id, formData)

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteLesson(product_id, id, page){
            try{
                await Api.delete('products/' + product_id + '/course/lessons/' + id)

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateLessonSequence(product_id){
            try{
                this.lesson_sequence['_method'] = 'put';
                await Api.post('/products/' + product_id + '/course/lesson-sequence',  this.lesson_sequence);
                return true;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCourseComments(product_id){
            try{
                const response = await Api.get('products/' + product_id + '/course/comments')
                this.course_comments = response.data.data;

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCourseComment(product_id, id){
            try{    
                const response = await Api.get('products/' + product_id + '/course/comments/' + id)
                this.course_comment = response.data.data;

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryApproveCourseComment(id){
            try{
                await Api.post('products/' + this.product.id + '/course/comments/' + id, { status: 'publish', '_method': 'put' })
                
                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryReplyCourseComment(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.course_comment);

                const response = await Api.post('products/' + this.product.id + '/course/comments/' + this.course_comment.id + '/reply', formData)
                this.course_comment = response.data.data;
                
                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteCourseComment(id, page){
            try{
                await Api.delete('products/' + this.product.id + '/course/comments/' + id)
            } catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        //ARQUIVOS

        async tryFiles(){
            try{
                const response = await Api.get('/products/' + this.product.id + '/files/')
                this.files = response.data.data;

                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryFile(id){
            try{
                const response = await Api.get('/products/' + this.product.id + '/files/' + id);
                this.file = response.data.data;
                
                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateFile(params, page, id){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.file);
                formData['_method'] = 'put';

                await Api.post('products/' + this.product.id + '/files/' + id, formData)

                return true;  
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page)
                return false;
            }
        },

        async tryCreateFile(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.file);

                await Api.post('products/' + this.product.id + '/files/', formData)

                return true;  
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error,page);
                return false;
            }
        },

        async tryDeleteFile(id){
            try{
                await Api.delete('products/' + this.product.id + '/files/' + id)

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },


        //LOTES

        async tryLotes(){
            try{
                const response = await Api.get('/products/' + this.product.id + '/lotes/')
                this.lotes = response.data.data;

                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryLote(id){
            try{
                const response = await Api.get('/products/' + this.product.id + '/lotes/' + id);
                this.lote = response.data.data;
                
                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateLote(params, page, id){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.lote);
                formData['_method'] = 'put';

                await Api.post('products/' + this.product.id + '/lotes/' + id, formData)

                return true;  
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page)
                return false;
            }
        },

        async tryCreateLote(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.lote);

                await Api.post('products/' + this.product.id + '/lotes/', formData)

                return true;  
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error,page);
                return false;
            }
        },

        async tryDeleteLote(id){
            try{
                await Api.delete('products/' + this.product.id + '/lotes/' + id)

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //CAMPOS PERSONALIZADOS
        //CAMPOS PERSONALIZADOS
        
        async tryFields(){
            try{
                const response = await Api.get('/products/' + this.product.id + '/fields/')
                this.personalized_fields = response.data.data;

                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryField(id){
            try{
                const response = await Api.get('/products/' + this.product.id + '/fields/' + id);
                this.personalized_field = response.data.data;
                
                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateField(id, params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.personalized_field);
                formData['_method'] = 'put';

                await Api.post('products/' + this.product.id + '/fields/' + id, formData)

                useGlobalStore().successMessage('Campo atualizado com sucesso');

                return true;  
            } catch (error){
                useGlobalStore().tryIncomingErrors(error, page)
                return false;
            }
        },

        async tryCreateField(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.personalized_field);

                await Api.post('products/' + this.product.id + '/fields/', formData)

                useGlobalStore().successMessage('Campo criado com sucesso');
                return true;  
            }catch(error){
                useGlobalStore().tryIncomingErrors(error,page);
                return false;
            }
        },

        async tryDeleteField(id){
            try{
                await Api.delete('products/' + this.product.id + '/fields/' + id)

                useGlobalStore().successMessage('Campo removido com sucesso');
                return true;
            }catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //ORDER-BUMP
        //ORDER-BUMP

        async tryOrdersBumps(){
            try{
                const response = await Api.get('/products/' + this.product.id + '/bump/')
                this.bumps = response.data.data;
                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryOrderBump(id){
            try{
                const response = await Api.get('/products/' + this.product.id + '/bump/' + id);
                this.bump = response.data.data;
                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateOrderBump(params, page, id){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.bump);
                formData['_method'] = 'put';
                await Api.post('products/' + this.product.id + '/bump/' + id, formData)
                return true;  
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page)
                return false;
            }
        },

        async tryCreateOrderBump(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.bump);
                await Api.post('products/' + this.product.id + '/bump/', formData)
                return true;  
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error,page);
                return false;
            }
        },

        async tryDeleteOrderBump(id){
            try{
                await Api.delete('products/' + this.product.id + '/bump/' + id)
                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //KITS
        //KITS

        async tryKits(){
            try{
                const response = await Api.get('/products/' + this.product.id + '/kit/')
                this.kits = response.data.data;
                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryKit(id){
            try{
                const response = await Api.get('/products/' + this.product.id + '/kit/' + id);
                this.kit = response.data.data;
                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateKit(params, page, id){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.kit);
                formData['_method'] = 'put';
                await Api.post('products/' + this.product.id + '/kit/' + id, formData)
                return true;  
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page)
                return false;
            }
        },

        async tryCreateKit(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.kit);
                await Api.post('products/' + this.product.id + '/kit/', formData)
                return true;  
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error,page);
                return false;
            }
        },

        async tryDeleteKit(id){
            try{
                await Api.delete('products/' + this.product.id + '/kit/' + id)
                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //COPRODUTORES
        //COPRODUTORES

        async tryCoproductors(){
            try{
                const response = await Api.get('/products/' + this.product.id + '/coproductors');
                this.coproductors = response.data.data;
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCoproductor(id){
            try{
                const response = await Api.get('/products/' + this.product.id + '/coproductors/' + id)
                this.coproductor = response.data.data;
                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateCoproductor(params, page, id){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.coproductor);
                formData['_method'] = 'put';

                await Api.post('products/' + this.product.id + '/coproductors/' + id, formData)

                useGlobalStore().successMessage('Coprodutor atualizado com sucesso');

                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error, page)
                return false;
            }
        },

        async tryCreateCoproductor(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.coproductor);

                await Api.post('products/' + this.product.id + '/coproductors', formData)

                useGlobalStore().successMessage('Coprodutor adicionado com sucesso');

                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error,page);
                return false;
            }
        },

        async tryDeleteCoproductor(id){
            try{
                await Api.delete('products/' + this.product.id + '/coproductors/' + id)

                useGlobalStore().successMessage('Coprodutor removido com sucesso');
                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //EVENTOS - leadlovers
        //EVENTOS - leadlovers

        async tryEventsLeadlovers(){
            try{
                const response = await Api.get('products/' + this.product.id + '/leadlovers/');
                this.events_leadlovers = response.data.data
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryEventLeadlovers(id){
            try{
                const response = await Api.get('products/' + this.product.id + '/leadlovers/' + id);
                this.event_leadlovers = response.data.data
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        
        async tryEventLeadloversMachines(){
            try{
                const response = await Api.get('products/' + this.product.id + '/leadlovers/machines')
                this.event_leadlovers_machines = response.data.data
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryEventLeadloversLevels(params = null){
            try{
                const response = await Api.get('products/' + this.product.id + '/leadlovers/levels', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.event_leadlovers_levels = response.data
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryEventLeadloversLevelsEmails(params = null){
            try{
                const response = await Api.get('products/' + this.product.id + '/leadlovers/levels/email-sequences' ,{params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.event_leadlovers_emails = response.data
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateEventLeadlovers(id, params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.event_leadlovers);
                formData['_method'] = 'put';

                await Api.post('products/' + this.product.id + '/leadlovers/' + id, formData);

                useGlobalStore().successMessage('Evento atualizado com sucesso');
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryCreateEventLeadlovers(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.event_leadlovers);

                await Api.post('products/' + this.product.id + '/leadlovers/', formData);

                useGlobalStore().successMessage('Evento criado com sucesso');
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteEventLeadlovers(id){
            try{
                await Api.delete('products/' + this.product.id + '/leadlovers/' + id)

                useGlobalStore().successMessage('Evento removido com sucesso');
                return true;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        
        //EVENTOS - mautic
        //EVENTOS - mautic

        async tryEventsMautic(){
            try{
                const response = await Api.get('products/' + this.product.id + '/mautic/');
                this.events_mautic = response.data.data
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryEventMautic(id){
            try{
                const response = await Api.get('products/' + this.product.id + '/mautic/' + id);
                this.event_mautic = response.data.data
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateEventMautic(id, params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.event_mautic);
                formData['_method'] = 'put';

                await Api.post('products/' + this.product.id + '/mautic/' + id, formData);

                useGlobalStore().successMessage('Evento atualizado com sucesso');
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryCreateEventMautic(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.event_mautic);

                await Api.post('products/' + this.product.id + '/mautic/', formData);

                useGlobalStore().successMessage('Evento criado com sucesso');
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteEventMautic(id){
            try{
                await Api.delete('products/' + this.product.id + '/mautic/' + id)

                useGlobalStore().successMessage('Evento removido com sucesso');
                return true;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //EVENTOS - mex
        //EVENTOS - mex

        async tryEventsMex(){
            try{
                const response = await Api.get('products/' + this.product.id + '/mex/');
                this.events_mex = response.data.data
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryEventMex(id){
            try{
                const response = await Api.get('products/' + this.product.id + '/mex/' + id);
                this.event_mex = response.data.data
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateEventMex(id, params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.event_mex);
                formData['_method'] = 'put';

                await Api.post('products/' + this.product.id + '/mex/' + id, formData);

                useGlobalStore().successMessage('Evento atualizado com sucesso');
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryCreateEventMex(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.event_mex);

                await Api.post('products/' + this.product.id + '/mex/', formData);

                useGlobalStore().successMessage('Evento criado com sucesso');
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteEventMex(id){
            try{
                await Api.delete('products/' + this.product.id + '/mex/' + id)

                useGlobalStore().successMessage('Evento removido com sucesso');
                return true;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //ARQUIVOS

        async tryUpsells(){
            try{
                const response = await Api.get('/products/' + this.product.id + '/upsells/')
                this.upsells = response.data.data;

                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpsell(id){
            try{
                let upsell = useGlobalStore().getObjectById(this.upsells, id);

                if(upsell){
                    this.upsell = upsell;
                }else {
                    const response = await Api.get('/products/' + this.product.id + '/upsells/' + id);
                    this.upsell = response.data.data;
                }
                
                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateUpsell(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.upsell);
                formData['_method'] = 'put';

                await Api.post('products/' + this.product.id + '/upsells/' + this.upsell.id, formData)

                return true;  
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page)
                return false;
            }
        },

        async tryUpdateUpsellCustomization(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.upsell);
                formData['_method'] = 'put';

                await Api.post('products/' + this.product.id + '/upsells/' + this.upsell.id + '/customization', formData)

                return true;  
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page)
                return false;
            }
        },

        async tryCreateUpsell(params, page){

            try{
                let formData = useGlobalStore().getOnlyParams(params, this.upsell);

                await Api.post('products/' + this.product.id + '/upsells/', formData)

                return true;  
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error,page);
                return false;
            }
        },

        async tryDeleteUpsell(id){
            try{
                await Api.delete('products/' + this.product.id + '/upsells/' + id)

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //UPSELLS TEMPLATE
        //UPSELLS TEMPLATE

        async tryUpsellsTemplates(){
            try{
                const response = await Api.get('products/upsells/templates')
                this.upsells_templates = response.data.data;

                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpsellsTemplate(id){
            try{
                let upsells_template = useGlobalStore().getObjectById(this.upsells_templates, id);

                if(upsells_template){
                    this.upsells_template = upsells_template;
                }else {
                    const response = await Api.get('/products/upsells/templates/'+ id);
                    
                    this.upsells_template = response.data.data;
                }
                
                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateUpsellCustomizationTemplate(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.upsells_template);
                await Api.post('products/'+ + this.product.id + '/upsells/' + this.upsell.id + '/templates', formData);

                return true;  
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page)
                return false;
            }
        },

        async tryDeleteUpsellTemplate(id){
            try{
                await Api.delete('products/upsells/templates/' + id)

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //CRUD PRODUCT

        async tryCreateProduct(params, page) {
            try {
                let formData = useGlobalStore().getOnlyTabsParams(params, this.product);
                await Api.post('/products', formData);
                useGlobalStore().successMessage('Produto cadastrado com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateProduct(params, page) {
            try {
                let formData = useGlobalStore().getOnlyTabsParams(params, this.product);

                formData['_method'] = 'put';

                await Api.post('products/' + this.product.id + '/', formData );
                useGlobalStore().successMessage('Produto atualizado com sucesso');
                
                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error, page, params);
                return false;
            }
        },

        async tryDuplicateProduct(id) {
            try {
                const response = await Api.get('products/' + id + '/duplicate');

                useGlobalStore().successMessage('Produto duplicado com sucesso');

                return response.data.data.product.id;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryDeleteProduct(id) {
            try {
                await Api.delete('products/' + id);
                await this.tryProducts();

                useGlobalStore().successMessage('Produto removido com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
    }
});
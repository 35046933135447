<template>
    <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="products_productor.length" @changePage="changePage">
        <tr v-for="(product, key) in products_productor" :key="key">
            <td width="10">{{ product.id }}</td>
            <td>
                <div class="d-flex align-items-center">
                    <div class="product_image" :style="'background: url(' + product.image + ');'"></div>
                    {{product.title }}
                    <BaseBadget v-if="product.tag" small class="ms-1" color="#424242" :text="product.tag" />
                </div>
            </td>
            <td><div class="sensitive_information">{{ product.status_name }}</div></td>
            <td><div class="sensitive_information">{{ globalStore.formatMoney(product.price) }}</div></td>
            <td><BaseStoreDot :color="product.store.color" :title="product.store.title"/></td>
            <td class="actions">
                <span v-if="product.status !== 'draft' && product.status !== 'disabled'" @click="copy(product.short_url)" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip :title="tooltipText"><font-awesome-icon icon="fa-regular fa-link" /></span>
                <router-link :to="'/products/' + product.id" v-if="globalStore.hasPermissionTo('edit_product')" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar"><font-awesome-icon icon="fas fa-edit" /></router-link>
                <span v-if="globalStore.hasPermissionTo('duplicate_product') || globalStore.hasPermissionTo('destroy_product')" class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"><font-awesome-icon icon="fas fa-ellipsis-h" /></span>
                <div class="dropdown-menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                    <span v-if="globalStore.hasPermissionTo('duplicate_product')" class="dropdown-item d-flex justify-content-center flex-column" @click="duplicateProduct(product.id)">Duplicar</span>
                    <span v-if="globalStore.hasPermissionTo('destroy_product')" class="dropdown-item d-flex justify-content-center flex-column" @click="openPopup(product.id)">Remover</span>
                </div>
            </td>
        </tr>
    </BaseTable>
    <router-view></router-view>
        <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
            <div class="confirm_action">
                <div class="popup ">
                    <p class="title">Atenção</p>
                    <p class="text">Deseja remover esse produto?</p>
                    <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                    <BaseInput
                        label=''
                        type="text"
                        id="excludeTextConfirm"
                        v-model="excludeTextConfirm"
                        :page="route.name"
                        class="col-12"
                    />
                    <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                    <baseButton @click="deleteProduct" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
                </div>
            </div> 
        </BasePopup>
</template>
<script setup>
import { ref, onBeforeMount, watch, shallowRef } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/stores/products';
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { products_productor, paginate } = storeToRefs(productStore);

const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const tableColumns = ['ID', 'Nome', 'Status', 'Valor', 'Loja'];

const route = useRoute();
const router = useRouter();

const isLoading = ref(false);

const params = ref({
    'search': null,
    'page': null,
    'type': 'productor',
    'status': ' ',
    'tags': null,
});

const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null)

const emit = defineEmits(["removeLoading"]);

onBeforeMount(() => {
    if(productStore.products_productor.length == undefined || router.options.history.state.replaced === true){
        isLoading.value = true;
    }

    if(route.query.search || route.query.tags || route.query.status) {
        Object.keys(productStore.filters_products).forEach((key) => {
            let param = route.query[key];
            productStore.filters_products[key] = (param == undefined) ? null : param;

            if(key == 'tags') {
                if((productStore.filters_products[key]) && productStore.filters_products[key].constructor !== Array) {
                    let currentThing = productStore.filters_products[key];
                    productStore.filters_products[key] = [];
                    productStore.filters_products[key].push(currentThing);
                }
            } else if(key == 'status'){
                if((productStore.filters_products[key]) && productStore.filters_products[key].constructor !== Array) {
                    let currentThing = productStore.filters_products[key];
                    productStore.filters_products[key] = '';
                    productStore.filters_products[key] = currentThing;
                }
            }
        })
    }

    loadProducts();
});

async function loadProducts() {
    let usedParams = {};
 
    productStore.filters_products.type = "productor";
    Object.keys(productStore.filters_products).forEach((key) => {
        if(productStore.filters_products[key] != null && productStore.filters_products[key] != '') {
            let currentParams = {
                ...usedParams,
                [key]: productStore.filters_products[key]
            };
            usedParams = currentParams;
        }
    });
    
    await productStore.tryProducts(usedParams).then(function (success){
        if(success){
            isLoading.value = false;
            emit('removeLoading')
            globalStore.loader('hide');
        } else {
            isLoading.value = false;
            emit('removeLoading')
            globalStore.loader('hide');
        }
    })
}

let isChangingPage = false;
async function changePage(p) {
    if(isChangingPage) return;
    productStore.filters_products.page = p;

    Object.keys(productStore.filters_products).forEach((key) => {

        if(key == 'tags'){
            productStore.filters_products[key] = route.query['tags']
        } else if(key == 'status'){
            productStore.filters_products[key] = route.query['status']
        }
    });

    globalStore.loader('show');
    isChangingPage = true;
    loadProducts(false).then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

let loadDefaultStore = false;
watch(defaultStore, function () {
    if(!loadDefaultStore) {
        loadDefaultStore = true;
        if(route.query.status === undefined){
            productStore.filters_products.status = null;
        } else {
            route.query.status
        }

        productStore.filters_products.page = null;
        loadProducts(params).then(()=> {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});

let isDeleting = false;
let productToDelete = null;
async function deleteProduct() {
    if(!isDeleting && !isLoading.value && productToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        await productStore.tryDeleteProduct(productToDelete).then(() =>{
            productStore.tryProducts(productStore.filters_products).then(() =>{
                router.replace({query: productStore.filters_products});
                globalStore.loader('hide');
                isDeleting = false;
                confirmAction.value = null;
                excludeTextConfirm.value = null;
                isLoading.value = false;
            })
        })
    }
}

async function duplicateProduct(product_id){
    globalStore.loader('show');
    isLoading.value = true
    productStore.tryDuplicateProduct(product_id).then(function(id_duplicated_product) {
        globalStore.cleanObject(productStore.product);
        router.push('/products/' + id_duplicated_product)
        globalStore.loader('hide');
        isLoading.value = false;
    })
}

var tooltipText = "Copiar Link"

function copy(short_url) {

    const tooltipInner = document.querySelector('.tooltip-inner')
    tooltipInner.innerHTML = 'Link copiado'
    
    var copyText = short_url;
    navigator.clipboard.writeText(copyText);
}

function openPopup(product) {
    productToDelete = product
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}

</script>
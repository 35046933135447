<template >
    <div v-if="orderStore.order.id">
        <div class="container px-2 px-lg-4">
            <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
                <template v-slot:title>
                    <BaseBadget :text="order.status.name" :color="order.status.color" :icon="order.status.icon" />
                    <font-awesome-icon class="top_icon top_icon_margin" v-if="order.finished_mark" icon="fas fa-star"/>

                    <span
                        class="top_icon_margin"
                        data-bs-toggle="tooltip" data-bs-placement="top" 
                        data-bs-animation="true" v-tooltip title="Computador"
                    >
                        <font-awesome-icon 
                            class="top_icon"
                            v-if="order.device == 'desktop'"
                            icon="fas fa-laptop"
                        />
                    </span>

                    <span
                        data-bs-toggle="tooltip" data-bs-placement="top" 
                        data-bs-animation="true" v-tooltip title="Celular"
                    >
                        <font-awesome-icon 
                            class="top_icon"
                            v-if="order.device == 'phone'"
                            icon="fas fa-mobile-screen"
                        />
                    </span>

                    <span
                        data-bs-toggle="tooltip" data-bs-placement="top" 
                        data-bs-animation="true" v-tooltip title="Tablet"
                    >
                        <font-awesome-icon 
                            class="top_icon"
                            v-if="order.device == 'tablet'"
                            icon="fas fa-tablet-screen"
                        />
                    </span>
                </template>
                <baseButton class="btn btn-white" type="button" data-bs-toggle="dropdown" aria-expanded="false"><font-awesome-icon icon="fa-solid fa-ellipsis-vertical" /></baseButton>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li class="dropdown-item"  @click="resendWebhooks(order.id)" :disabled="isLoading">Reenviar webhooks</li>
                </ul>
            </BaseHeader>
        </div>
        <div class="container px-2 px-lg-4">
            <div class="box_content nav_div">
                <nav>
                    <div class="nav" id="nav-tab" role="tablist">
                        <router-link :to="'/orders/'+ order_id" :class="[route.path === '/orders/'+ order_id ? 'active': '']">
                            Informações
                        </router-link>
                        <router-link :to="'/orders/' + order_id +'/tax-and-comissions'" :class="[route.path === '/orders/' + order_id +'/tax-and-comissions' ? 'active': '']">
                            Taxas e comissões
                        </router-link>
                        <router-link :to="'/orders/' + order_id + '/files'" :class="[route.path === '/orders/' + order_id + '/files' ? 'active': '']">
                            Arquivos
                        </router-link>
                        <router-link :to="'/orders/' + order_id + '/events'" :class="[route.path === '/orders/' + order_id + '/events' ? 'active': '']">
                            Eventos
                        </router-link>
                        <template v-if="order.show_cancelable_tab && globalStore.hasPermissionTo('refund_order')">
                            <router-link v-if="order.is_cancelable || (order.refund &&  order.is_not_cancelable_reason === undefined)" :to="'/orders/' + order_id + '/refund'" :class="[route.path === '/orders/' + order_id + '/refund' ? 'active': '']">
                                Reembolso
                            </router-link>
                            <a href="#" v-else data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" data-bs-html="true" v-tooltip :title="order.is_not_cancelable_reason"><s>Reembolso</s></a>
                        </template>
                        <router-link v-if="order.has_chargeback" :to="'/orders/' + order_id + '/chargeback'" :class="[route.path === '/orders/' + order_id + '/chargeback' ? 'active': ''] + [order.chargeback.status.slug == 'pending' ? ' chargeback' : '']">
                            <span>Chargeback</span>
                            <font-awesome-icon v-if="order.chargeback.status.slug == 'pending'" :icon="['fas', 'circle-exclamation']" />
                        </router-link>
                    </div>
                </nav>
            </div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { ref, computed, onBeforeMount, watch } from 'vue';
import { storeToRefs } from 'pinia';

const route = useRoute();
const router = useRouter();

const order_id = computed (function () {
    return route.params.id_order;
});

const title = ref({title: 'Pedido #' + order_id.value, icon: 'fa-shopping-cart'});

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

import { useOrderStore } from '@/stores/order';
const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

const previousPage = ref(null);
const isLoading = ref(false);

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('edit_order')) {
        return router.push('/403');
    }

    previousPage.value = router.options.history.state.back;
    let order_preloaded = useGlobalStore().getObjectById(orderStore.orders, order_id.value);
    
    if(order_preloaded){
        orderStore.order = order_preloaded;
        orderStore.tryOrderEdit(orderStore.order.id);
    }else {
        loadOrder();
    }
});

watch(order_id, async () => {
    if(orderStore.order.id != order_id.value && order_id.value != undefined) {
        loadOrder();
        title.value.title = 'Pedido #' + order_id.value;
    }
});

async function loadOrder() {
    globalStore.loader('show');
    await orderStore.tryOrderEdit(order_id.value);
    globalStore.loader('hide');
}

async function resendWebhooks(id){
    globalStore.loader('show');
    isLoading.value = true;
    await orderStore.tryResendWebhoks(route.name, id).then(() =>{
        globalStore.loader('hide');
        isLoading.value = false;
    });

}

</script>

<style scoped>
.top_icon_margin{
    margin-left: 13px;
}

.top_icon {
    fill: #215eda;
    color: #215eda;
}

#nav-tab {
    display: block !important;
    white-space: nowrap;
    overflow-x: auto;
}

.chargeback.active {
    border-color: #dc3545 !important;
}
.chargeback span {
    color: #dc3545 !important;
    display: inline-block;
    margin-right: 5px;
}
.chargeback svg {
    color: #dc3545 !important;
}

.disabled_cancel{
    cursor: default !important;
    font-weight: 600;
    color: #767676;
    opacity: .6;
}

</style>
